import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit, OnDestroy {

  sub;
  url: SafeResourceUrl = null;
  loadedTimes = 0;

  constructor(
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {

  }

  loaded() {
    this.loadedTimes++;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params['productId']) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + params['productId']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  close() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
