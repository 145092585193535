import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {ProductComponent} from "./pages/product/product.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: {state: 'dashboard'}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {state: 'dashboard'},
    children: [
      {
        path: 'product/:productId',
        component: ProductComponent,
        data: {state: 'product'}
      }
    ]
  },


];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
