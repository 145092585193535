import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import Swiper from 'swiper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('swiperContainer', {static: false}) swiperContainer;


  elements = [
    {
      thumbnail: 'assets/images/xycle.jpg',
      name: 'XYCLE',
      type: 'Projekt',
      url: 'xycle.altotest.de'
    },
    {
      thumbnail: 'assets/images/innotape.jpg',
      name: 'INNO TOUCH',
      type: 'Projekt',
      url: 'inno-touch.altotest.de'
    },
    {
      thumbnail: 'assets/images/pizzabuilder.jpg',
      name: 'Konfigurator',
      type: 'Modul',
      url: 'demo-pizzabuilder.altotest.de'
    },
    {
      thumbnail: 'assets/images/rolex.jpg',
      name: '3D-Modell',
      type: 'Modul',
      url: 'demomtt.altotest.de'
    },
    {
      thumbnail: 'assets/images/tooltips.jpg',
      name: 'Tooltips',
      type: 'Modul',
      url: 'tooltips.altotest.de'
    },
    {
      thumbnail: 'assets/images/secit.jpg',
      name: 'Sec-IT',
      type: 'Projekt',
      url: 'secit.altotest.de'
    },
    {
      thumbnail: 'assets/images/carvisualizer.jpg',
      name: 'Visualizer',
      type: 'Projekt',
      url: 'carvisualizer.plus360degrees.com/threejs/'
    },
    {
      thumbnail: 'assets/images/treckerspiel.jpg',
      name: 'Treckerspiel',
      type: 'Projekt',
      url: 'trecker.altotest.de/player2/mtt.html'
    }
  ];

  private swiper: any = null;


  constructor() {
  }

  ngOnInit() {
  }


  public ngAfterViewInit() {
    this.initSlider();
  }

  public initSlider() {
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      slidesPerView: 4,
      spaceBetween: 40,
      freeMode: true,
      freeModeSticky: true,
      direction: 'horizontal',
      centeredSlides: true,
      loop: false
    });
  }

}
